require("!svg-sprite-loader!./arrow-down.svg");
require("!svg-sprite-loader!./arrow-end.svg");
require("!svg-sprite-loader!./arrow-left.svg");
require("!svg-sprite-loader!./arrow-right.svg");
require("!svg-sprite-loader!./arrow-start.svg");
require("!svg-sprite-loader!./arrow-up.svg");
require("!svg-sprite-loader!./arrow-long-right.svg");
require("!svg-sprite-loader!./arrow-long-left.svg");
require("!svg-sprite-loader!./arrow-long-down.svg");
require("!svg-sprite-loader!./arrow-long-up.svg");
require("!svg-sprite-loader!./back-arrow.svg");
require("!svg-sprite-loader!./barcode.svg");
require("!svg-sprite-loader!./chat.svg");
require("!svg-sprite-loader!./check-mark.svg");
require("!svg-sprite-loader!./circle.svg");
require("!svg-sprite-loader!./circle-hollow.svg");
require("!svg-sprite-loader!./clessidra.svg");
require("!svg-sprite-loader!./clock.svg");
require("!svg-sprite-loader!./cross.svg");
require("!svg-sprite-loader!./dashboard.svg");
require("!svg-sprite-loader!./delete.svg");
require("!svg-sprite-loader!./doc.svg");
require("!svg-sprite-loader!./dropdown.svg");
require("!svg-sprite-loader!./download.svg");
require("!svg-sprite-loader!./download-arrow.svg");
require("!svg-sprite-loader!./edit.svg");
require("!svg-sprite-loader!./empty.svg");
require("!svg-sprite-loader!./error.svg");
require("!svg-sprite-loader!./error-fill.svg");
require("!svg-sprite-loader!./equal.svg");
require("!svg-sprite-loader!./eye.svg");
require("!svg-sprite-loader!./fullscreen.svg");
require("!svg-sprite-loader!./eye-crossed.svg");
require("!svg-sprite-loader!./expand-vertical.svg");
require("!svg-sprite-loader!./idea.svg");
require("!svg-sprite-loader!./info-filled.svg");
require("!svg-sprite-loader!./info-unfilled.svg");
require("!svg-sprite-loader!./layers.svg");
require("!svg-sprite-loader!./logout.svg");
require("!svg-sprite-loader!./magnifying-glass.svg");
require("!svg-sprite-loader!./mail.svg");
require("!svg-sprite-loader!./menu.svg");
require("!svg-sprite-loader!./minimize.svg");
require("!svg-sprite-loader!./minus.svg");
require("!svg-sprite-loader!./networth.svg");
require("!svg-sprite-loader!./notification.svg");
require("!svg-sprite-loader!./password.svg");
require("!svg-sprite-loader!./phone.svg");
require("!svg-sprite-loader!./play-full.svg");
require("!svg-sprite-loader!./play.svg");
require("!svg-sprite-loader!./ellipsis.svg");
require("!svg-sprite-loader!./plus.svg");
require("!svg-sprite-loader!./profile.svg");
require("!svg-sprite-loader!./profile-full.svg");
require("!svg-sprite-loader!./tick.svg");
require("!svg-sprite-loader!./tick-fill.svg");
require("!svg-sprite-loader!./tick-unfilled.svg");
require("!svg-sprite-loader!./tv.svg");
require("!svg-sprite-loader!./ellipsis-v.svg");
require("!svg-sprite-loader!./ellipsis.svg");
require("!svg-sprite-loader!./nl-error-indicator.svg");
require("!svg-sprite-loader!./nl-validation-error.svg");
require("!svg-sprite-loader!./pen-write.svg");
require("!svg-sprite-loader!./person.svg");
require("!svg-sprite-loader!./settings.svg");
require("!svg-sprite-loader!./shine.svg");
require("!svg-sprite-loader!./spotlight.svg");
require("!svg-sprite-loader!./send.svg");
require("!svg-sprite-loader!./star.svg");
require("!svg-sprite-loader!./star-full.svg");
require("!svg-sprite-loader!./warning-fill.svg");

require("!svg-sprite-loader!./persona/00-neutral.svg");
require("!svg-sprite-loader!./persona/01-trend-warrior.svg");
require("!svg-sprite-loader!./persona/02-balanced-individual.svg");
require("!svg-sprite-loader!./persona/03-value-explorer.svg");
require("!svg-sprite-loader!./persona/04-growth-chaser.svg");
require("!svg-sprite-loader!./persona/05-income-inspector.svg");

require("!svg-sprite-loader!./lux/lux-balanced.svg");
require("!svg-sprite-loader!./lux/lux-growth.svg");
require("!svg-sprite-loader!./lux/lux-income.svg");
require("!svg-sprite-loader!./lux/lux-trend.svg");
require("!svg-sprite-loader!./lux/lux-value.svg");
